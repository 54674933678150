<template>
	<app tabbar>
		<div class="list">
			<h1>操作帮助中心</h1>
			
			<ul>
				<li v-for="(item, i) in videoList" :key="i">
					<video :src="item.video" controls></video>
					<p>{{item.name}}</p>
				</li>
			</ul>
		</div>
	</app>
</template>

<script>
	export default {
		data() {
			return {
				videoList: [{
					name: '企业保函申报购买操作流程',
					video: 'https://szbhapi.base.tkwsc.cn/upload/企业保函申报购买操作流程.mp4'
				}, {
					name: '保险单审批操作教程',
					video: 'https://szbhapi.base.tkwsc.cn/upload/保险单审批操作教程.mp4'
				}]
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.list {
		padding: 0 .3rem;
		
		h1 {
			position: relative;
			padding-left: .2rem;
			margin: .3rem 0;
			line-height: .52rem;
			font-size: .36rem;
			font-weight: bold;
			color: #1F1F1F;
			
			elder({
				line-height: .7rem,
				font-size: .5rem
			})
			
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: .1rem;
				width: .06rem;
				height: .32rem;
				background: #0366F1;
				
				elder({
					top: .12rem,
					height: .44rem
				})
			}
		}
		
		li {
			margin-bottom: .2rem;
			
			video {
				width: 6.9rem;
				height: 3.9rem;
				background: #000;
			}
			
			p {
				line-height: .8rem;
				font-size: .3rem;
				
				elder({
					line-height: 1rem,
					font-size: .44rem
				})
			}
		}
	}
</style>
